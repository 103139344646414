/*
    INDEX Design Elements
*/
@import (reference) "vars.less";
@import (reference) "mixins.less";
@import (reference) "generals.less";


#welcome {
    background-image:url('@{img-path}v5/biggerbg.png');
    background-size: 100% 150%;
    padding: 4em 2em 2.5em 2em; 
    margin-top: 10px;
    color: white; 
    font-family: @font-header-menu;
}

#teaser {
    .flex-box;
    .flex-wrap(wrap);
    .teaser-line {
        .flex(1);
        .flex-box;
        .flex-wrap(wrap);
    }
    .teaser-element {
        .flex(1);
        min-width: 200px;
    }
}

#cardupdate { 
    background-image:url('@{img-path}v5/biggerbg.png');
    background-size: 100% 150%;
    padding: 0.5em; 
    margin-top: 10px;
    color: white; 
    font-family: @font-header-menu;
    .update-headline {
        font-size: 3em;
        margin: 0.5em auto;
        text-align: center;
    }
    .update-elements {
        .flex-box;
        .flex-wrap(no-wrap);
        max-height: 120px; 
        overflow: auto;
        .update-element {
            width: 110px;
            min-width: 110px;
            height: 95px; 
            margin: auto;
            border: 0;
            background-repeat: no-repeat; 
            background-position: center center;   
        }
    }    
}

#news {
    .news {
        .flex-box;
        .flex-wrap(wrap);
        flex-direction:column;
        -moz-flex-direction:column;
        -webkit-flex-direction:column;
        height: 300px;
        padding: 5px;
        &-element {
            .border-box;
            .border-radius(5px);
            position:relative;
            width: 25%;
            height: 150px;
            padding: 5px;
            &-wrapper { 
                .border-box;
                .border-radius(5px);
                position:relative;
                height: 100%;
                overflow:hidden;
                background-color: white;
                ///box-shadow: 0 0 5px rgba(0,0,0,0.25);
            }
            &-content {
                padding: 5px; 
            }
            &-headline {
                font-size: 1.5em;
                font-family: @font-header-menu;
                width: 100%;
            }
            &:nth-child(3n+1) .news-element-wrapper {
                border: 3px solid @logo-green;
            }
            &:nth-child(3n+2) .news-element-wrapper {
                border: 3px solid @logo-red;
            }
            &:nth-child(3n+3) .news-element-wrapper {
               border: 3px solid @logo-yellow;
            }
            &-text {
                
            }
            &-footer {
                .border-box;
                position:absolute;
                width: 100%;
                bottom: 0;
                right: 0;
                text-align:right;
                .bg-fade(transparent,white);
                &-comments {
                    display: inline-block;
                    background-color: @light-grey;
                    padding: 0.2em 0.4em;
                }
                &-link {
                    display: inline-block;
                    background-color: @dark-grey;
                    color: @light-grey;
                    padding: 0.2em 0.4em;
                    border: 0px;
                }
            }
            &:first-child {
                .news-element-headline { font-size: 2em; }
                display:block;
                width: 50%;
                height: 300px;
                border: 0px;
            }
        }
    }
    @media  
    only screen and (max-device-width : 640px),
    only screen and (max-width : 640px){
        .news {
            height: auto;
            flex-direction: row;
            -moz-flex-direction: row;
            -webkit-flex-direction: row;
            &-element  {
                width: 50%;
                &:first-child { width:100% }
            }
        }
    }
    @media  
    only screen and (max-device-width : 480px),
    only screen and (max-width : 480px){
        .news  {
            display:block;
            &-element { width: auto; }
        }
    }
}