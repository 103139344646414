/*
    MIXINS / FUNCTIONS
*/
@import (reference) "vars.less";

// ---------------------------------------------------->
// some usefull funktions and classes 
// ---------------------------------------------------->

.border-radius(@radius) {
  -webkit-border-radius: @radius;
     -moz-border-radius: @radius;
          border-radius: @radius;
}
.border-radius(@corner, @radius) {
  -webkit-border-@{corner}-radius: @radius;
     -moz-border-@{corner}-radius: @radius;
          border-@{corner}-radius: @radius;
}
.bg-fade(@colora,@colorb) {
	background-image: -webkit-linear-gradient(top, @colora, @colorb); 
	background-image: -moz-linear-gradient(top, @colora, @colorb); 
	background-image: -ms-linear-gradient(top, @colora, @colorb); 
	background-image: -o-linear-gradient(top, @colora, @colorb); 
}
.bg-fade-horizontal(@colora,@colorb) {
	background-image: -webkit-linear-gradient(left, @colora, @colorb); 
	background-image: -moz-linear-gradient(left, @colora, @colorb); 
	background-image: -ms-linear-gradient(left, @colora, @colorb); 
	background-image: -o-linear-gradient(left, @colora, @colorb); 
}
.flex(@value){
	-webkit-flex: @value;  
    -ms-flex: @value;     
    flex: @value;
}
.display-flex() {
    display: -webkit-flex; 
    display: flex;
}
.flex-justify-content(@a) {
    justify-content: @a;
    -moz-justify-content: @a;
    -webkit-justify-content: @a;
}
.flex-wrap(@a) {
    flex-wrap: @a;
    -moz-flex-wrap: @a;
    -webkit-flex-wrap: @a;
}
.transition(@values, @time){
    -webkit-transition-property: @values; 
    transition-property: @values;
    -webkit-transition-duration: @time; 
    transition-duration: @time;
}
.transition-delay(@time){
    -webkit-transition-delay: @time;
    transition-delay: @time;
}
.filter-grey(){
    -webkit-filter: grayscale(100%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0\'/></filter></svg>#grayscale"); /* Firefox 3.5+ */
    -moz-filter: grayscale(100%);
    -ms-filter: grayscale(100%);
    -o-filter: grayscale(100%);
    filter: grayscale(100%);
}
.filter-grey-off(){
    -webkit-filter: grayscale(0%);
    filter: url("data:image/svg+xml;utf8,<svg xmlns=\'http://www.w3.org/2000/svg\'><filter id=\'grayscale\'><feColorMatrix type=\'matrix\' values=\'1 0 0 0 0, 0 1 0 0 0, 0 0 1 0 0, 0 0 0 1 0\'/></filter></svg>#grayscale");
    -moz-filter: grayscale(0%);
    -ms-filter: grayscale(0%);
    -o-filter: grayscale(0%);
    filter: grayscale(0%);
}