/*
    Var Definitions
*/

/* fonts ----------------------------------------------> */
@font-basic: 'Open Sans', Helvetica, Arial, sans-serif;
@font-cursive: 'Grand Hotel', 'Times New Roman' cursive;
@font-written: 'Indie Flower', 'Comic Sans MS', cursive;
@font-headlines: 'Eras', Helvetica, Arial, sans-serif;
@font-header-menu: 'Homenaje', Helvetica, Arial, sans-serif;
/* font-sizes -----------------------------------------> */
@font-extrasmall: 0.5em;
@font-small: 0.75em;
@font-normal: 13px;
@font-large: 1.5em;
@font-extralarge: 2.0em;

/* Colors ---------------------------------------------> */
@light-grey: #eee;
@mid-grey: #808080;
@dark-grey: #333;
@turquoise: #26d6cd;
@yellow: #f1d50d;
@green: #98d012;
@logo-red: #ec424a;
@logo-green: #5cdcb6;
@logo-yellow: #ffd545;

/* images ---------------------------------------------> */
@img-path: "../../../../img/";
@logo-img: "@{img-path}v5/logo.png";
@logo-height: 90px;
@logo-width: 138px;
@logo-img-red: "@{img-path}v5/trade.png";
@logo-img-green: "@{img-path}v5/community.png";
@logo-img-yellow: "@{img-path}v5/games.png";
@logo-img-size: 50px;

/* widths & heights -----------------------------------> */
@wrapper-width: 85%;
@max-wrapper-width: 1250px;
@header-height: @header-user-height;
@header-navi-height: 35px;
@header-user-height: 50px;